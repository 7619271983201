// ----------------------------------------------------------------------

export default function Popover() {
  return {
    // MuiPopover: {
    //   styleOverrides: {
    //     paper: {
    //       boxShadow: theme.customShadows.z12,
    //     },
    //   },
    // },
  };
}
